import { createStore } from 'vuex'
import app from './modules/app';
import user from './modules/user';
import auth from './modules/auth';
import etablissements from './modules/etablissements';
import offres from './modules/offres';
import reservations from './modules/reservations';
import produits from './modules/produits';
import commandes from './modules/commandes';
import experiences from './modules/experiences';
import etablissement from './modules/etablissement';


const debug = process.env.NODE_ENV !== "production";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    auth,
    user,
    etablissements,
    reservations,
    offres,
    produits,
    commandes,
    experiences,
    etablissement
  },
  strict: debug
})