<template>
    <nav class="navbar-default navbar-static-side pace-done" role="navigation">
        <div class="sidebar-collapse">
            <p class="logo"><img src="@/assets/img/logo-trinch-white.svg" alt="Trinch"></p>
            <ul class="nav metismenu" id="side-menu">
                <!-- <li class="nav-header">
                    <div class="dropdown profile-element">
                        <span class="block m-t-xs font-bold">{{ user.prenom }} {{ user.nom }}</span>
                        <a class="dropdown-item" href="">Editer</a> <a class="dropdown-item" href="" @click.prevent="logout">Logout</a>
                    </div>
                </li> -->
                <li>
                    <router-link to="/home"><i class="fa fa-th-large"></i> <span class="nav-label">Dashboard</span></router-link>
                </li>
                <li>
                    <router-link to="/etablissements"><i class="fa fa-home fa-lg"></i> <span class="nav-label">Mes Etablissements</span></router-link>
                </li>
                <li>
                    <router-link to="/boutiques"><i class="fa fa-home fa-lg"></i> <span class="nav-label">Mes Boutiques</span></router-link>
                </li>
                <!-- <li>
                    <router-link to="/etablissements"><i class="fa fa-circle fa-lg"></i> <span class="nav-label">Mes Capsules</span></router-link>
                </li> -->
                <li>
                    <router-link to="/offres"><i class="fa fa-ticket fa-lg"></i> <span class="nav-label">Mes Offres</span></router-link>
                </li>
                <li>
                    <router-link to="/produits"><i class="fa fa-glass fa-lg"></i> <span class="nav-label">Mes Produits</span></router-link>
                </li>
                <li>
                    <router-link to="/experiences"><i class="fa fa-cube fa-lg"></i> <span class="nav-label">Mes Experiences</span></router-link>
                </li>
                <li>
                    <router-link to="/commandes"><i class="fa fa-barcode fa-lg"></i> <span class="nav-label">Mes Commandes</span></router-link>
                </li>
                <!-- <li>
                    <router-link to="/livraisons"><i class="fa fa-barcode fa-lg"></i> <span class="nav-label">Mes Livraisons</span></router-link>
                </li> -->
                <li v-if="userService.hasRole('ROLE_HEBERGEUR')">
                    <router-link to="/reservations"><i class="fa fa-calendar fa-lg"></i> <span class="nav-label">Mes Réservations</span></router-link>
                </li>
            </ul>
            <div class="nav-user">
                <span class="user-img"><img src="@/assets/img/icon-user.svg"></span>
                <span class="user-name">{{ user.prenom }} {{ user.nom }}</span>
                <p><router-link to="/profil">Editer</router-link> | <a href="" @click.prevent="logout">Logout</a></p>
            </div>
        </div>
    </nav>
</template>

<script>
import { AUTH_LOGOUT } from "../store/actions/auth";
import userService from '@/service/user'

export default {
  name: 'NavBar',
   data() {
    return {
      userService: userService,
    };
  },
  computed: {
    user () {
      return this.$store.getters.getProfile
    },
  },
  methods: {
    logout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        console.log('logged out');
        this.$router.push("/login");
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
