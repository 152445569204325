import store from '@/store/main';

export default{
    hasRole(role) {
        let userRole = store.getters.getRoles
        if(Array.isArray(role)){
            //CHECK IF USER HAS ONE OF THE ROLES IN ARRAY
            let has_one_of_role = false;
            for(let r of role) {                
                if (userRole.includes(r)){
                    has_one_of_role = true;
                }
            }
            return has_one_of_role;
        }else if (userRole.includes(role)) {
            // role not authorised so redirect to home page
            return true
        }
        return false
    }
}