import axios from "axios";

export default{
    state: () => ({
      experiences:[],
      searching:false,
    }),
    mutations:{
      experiences(state,experiences) {
        state.experiences = experiences;
      },      
      searching(state,bool) {
        state.searching = bool;
      },
    },
    actions:{
      getExperiences(context){        
        context.searching = true;
        axios.get(process.env.VUE_APP_API + "/producteur/experiences", {}).then(resp => {
            context.commit('experiences',resp.data);
            context.commit('searching',false);
        }).catch((error) => {
          console.log("ERROR", error)
        });
      },      
    }
  }