<template>
  <component :is='layout'>
    <router-view></router-view>
  </component>
</template>

<script>
import { USER_REQUEST } from "./store/actions/user";
const default_layout = "default";

export default {
  name: 'App',
  components: {
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    }
  },
  mounted : function(){
    this.$store.dispatch(USER_REQUEST)
    this.$store.dispatch('init')
  }
}
</script>

<style>

</style>
