import axios from "axios";

export default{
    state: () => ({
      offres:[],
      searching:false,
    }),
    mutations:{
      offres(state,offres) {
        state.offres = offres;
      },      
      searching(state,bool) {
        state.searching = bool;
      },
    },
    actions:{
      getOffres(context){        
        context.searching = true;
        axios.get(process.env.VUE_APP_API + "/producteur/offres", {}).then(resp => {
            context.commit('offres',resp.data);
            context.commit('searching',false);
        }).catch((error) => {
          console.log("ERROR", error)
        });
      },      
    }
  }