import axios from "axios";

const state = { 
    typesOffre: [], 
    typesEtablissement: [],
    typesIntervenant: [],
    familleOffre: []
}

const getters = {
    getTypesOffre: (state) => state.typesOffre,
    getTypesEtablissement: (state) => state.typesEtablissement,
    getTypesIntervenant: (state) => state.typesIntervenant,
    getFamillesOffre: (state) => state.familleOffre,
};

const actions = {
    init: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API +"/web/init").then(resp => {
                commit('init', resp.data);
                resolve(resp.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
};

const mutations = {
    init: (state, resp) => {
        state.typesOffre = resp.types_offre
        state.typesEtablissement = resp.types_etablissement
        state.typesIntervenant = resp.types_intervenant
        state.familleOffre = resp.famille_offre
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};