import moment from 'moment'

const filters = {
    formatDate(date, format = 'DD MMMM YYYY - HH:mm') {
        return moment(date).locale("fr").format(format);
    },
    calculTTC(price, tva = 20){
        return price * (1 + tva/100)
    }
}
export default filters;