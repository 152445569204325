import axios from "axios";

export default{
    state: () => ({
      etablissements:[],      
      searching:false,
      message: 'erio'
    }),
    mutations:{
      etablissements(state,etablissements) {
        state.etablissements = etablissements;
      },      
      searching(state,bool) {
        state.searching = bool;
      },
      clearetablissements(state) {
        state.etablissements = [];
      }, 
      updateMessage (state, message) {
          state.message = message
      }
    },
    actions:{
      getEtablissements(context){
        context.commit('searching',true);
        context.commit('clearetablissements');
        context.searching = true;      
        axios.get(process.env.VUE_APP_API + "/producteur/etablissements", {}).then(resp => {            
            context.commit('etablissements',resp.data);
            context.commit('searching',false);
        }).catch(() => {            
        });
      },      
    }
  }