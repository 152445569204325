<template>
    <div id="wrapper gray-bg">
        <slot />
    </div>
</template>

<script>
export default {
  name: 'Default',
  data() {
    return {  
    }
  },
  methods: {
  },
  computed: {
  },
  components: {
  },
};
</script>