<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="text-center m-t-lg">
        <img src="@/assets/img/logo-trinch.png" alt="Trinch" width="200">
        <h1 class="m-t-lg">Bienvenue</h1>
        <!-- <div v-if="user.hasRole('ROLE_VENDEUR')">ROLE_VENDEUR OK</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/service/user";

export default {
  name: 'Home',
  data() {
    return {
      user: user
    }
  },
  computed: {
    count () {      
      return this.$store.getters.getProfile.nom
    },
    authStatus () {      
      return this.$store.getters.authStatus
    },
  },
  components: {
  },
};
</script>