import axios from "axios";

export default{
    state: () => ({
      etablissement:{},
    }),
    getters: {
      getEtablissement: (state) => state.etablissement,      
    },
    mutations:{
      etablissement(state,etablissement) {
        state.etablissement = etablissement;
      },
      titre(state,titre) {
        state.etablissement.titre = titre;
      },
      description(state,description) {
        state.etablissement.description = description;
      },      
    },
    actions:{
      getEtablissement(context, uuid){
        context.commit('searching',true);
        context.searching = true;        
        axios.get(process.env.VUE_APP_API + "/producteur/capsule/"+uuid, {}).then(resp => {       
            context.commit('etablissement',resp.data);
            context.commit('searching',false);
        }).catch(() => {
          
        });
      },
      postEtablissement(context){
        context.commit('searching',true);
        context.searching = true;
        let et = context.state.etablissement        

        axios.post(process.env.VUE_APP_API + "/producteur/capsule/" + et.uuid, et).then(() => {            
            context.commit('searching',false);
        }).catch((error) => {
            console.log("ERROR", error)
        });
      },
    }
  }