import axios from "axios";

export default{
    state: () => ({
      produits:[],
      searching:false,
    }),
    mutations:{
      produits(state,produits) {
        state.produits = produits;
      },      
      searching(state,bool) {
        state.searching = bool;
      },
    },
    actions:{
      getProduits(context){        
        context.searching = true;
        axios.get(process.env.VUE_APP_API + "/producteur/produits", {}).then(resp => {
            context.commit('produits',resp.data);
            context.commit('searching',false);
        }).catch((error) => {
          console.log("ERROR", error)
        });
      },      
    }
  }