<template>
  <div id="wrapper">
        
        
      <NavBar />

        <div id="page-wrapper">
            <!-- <div class="row border-bottom">
                <nav class="navbar navbar-static-top white-bg" role="navigation" style="margin-bottom: 0">
                    <div class="navbar-header">
                        <a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="#"><i class="fa fa-bars"></i> </a>
                    </div>
                    <ul class="nav navbar-top-links navbar-right">
                        <li>
                            <a href="">
                                <i class="fa fa-sign-out"></i> Logout
                            </a>
                        </li>
                    </ul>

                </nav>
            </div> -->
            <div class="wrapper wrapper-content animated fadeInRight">

               <slot />

            </div>
            <div class="footer">
                <strong>Copyright</strong> TRINCH &copy; 2020
            </div>
        </div>

    </div>
</template>

<script>
import NavBar from '@/components/NavBar'
export default {
  name: 'Default',
  data() {
    return {  
    }
  },
  methods: {
  },
  computed: {
  },
  components: {
      NavBar
  },
};
</script>