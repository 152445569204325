import { createApp } from 'vue'
import App from './App.vue'
import router from './router/main';
import store from './store/main';
import filters  from "./filters";
import Default from './layouts/Default';
import Simple from './layouts/Simple';
import VCalendar from 'v-calendar'
import VueUploadComponent from 'vue-upload-component'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

//import VueFileAgent from 'vue-file-agent';
// import 'vue-file-agent/dist/vue-file-agent.css';

import './assets/css/inspinia.scss';
import './assets/css/trinch.scss';


const app = createApp(App);

app.use(router)
.use(store)
.use(VCalendar)
.use(ElementPlus)
// .use(VueFileAgent)
app.config.globalProperties.$filters = filters

app.component('default-layout', Default)
app.component('simple-layout', Simple)
app.component('file-upload', VueUploadComponent)

app.mount('#app')
